// Define the breakpoints
$breakpoint-small: 600px;
$breakpoint-med-small: 960px;
$breakpoint-med: 1175px;

@mixin screen($size, $type: max, $pixels: $breakpoint-small) {
  @if $size == 'small' {
    @media screen and ($type + -width: $breakpoint-small) {
        @content;
    }
  }
  @else if $size == 'med-small' {
    @media screen and ($type + -width: $breakpoint-med-small) {
        @content;
    }
  }
  @else if $size == 'med' {
    @media screen and ($type + -width: $breakpoint-med) {
        @content;
    }
  }
 @else if $size == 'large' {
    @media screen and ($type + -width: $breakpoint-med) {
        @content;
    }
  }
  @else if $size == 'custom' {
    @media screen and ($type + -width: $pixels + px) {
     @content;
    }
  }
  @else {
    @content;
  }
}
// Using the mixin
.foo {
  @include screen(large) {
    width: 20%;
  }
  @include screen(med) {
    width: 40%;
  }
  @include screen(med-small) {
    width: 60%;
  }
  @include screen(small) {
    width: 80%;
  }
  @include screen(custom, max, 400) {
    width: 100%;
  }
}