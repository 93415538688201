@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
// Fonts
$baseFont: 'Roboto Condensed', sans-serif;
$secondaryFont: 'Cormorant Upright', serif;
$font-stack-system: 'Roboto Condensed', sans-serif;

// Breakpoints

$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;

// Colors
$brandPrimary: #18194a; // Dark Blue
$brandSecondary: #33afe4; // Light Blue
$brandTertiary: #dbae1d; // Yellow
$btnColor: #326296;

// Text Selection
$selectionColor: $brandPrimary;
$selectionTextColor: #fff;

// Links
$linkColor: $brandPrimary;
$linkHoverColor: $brandSecondary;
