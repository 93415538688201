
@media all and (max-width: 1200px) {
	header .topNav {
		display: none;
	}
	header .mobileToggle {
	display: block;
    font-size: 40px;
    position: absolute;
    top: 1em;
    right: 5%;
    color: white;
	}
	header .homeMobileToggle {
	display: block;
    font-size: 40px;
    position: absolute;
    top: 30%;
    right: 5%;
    color: white;
	}
	.sideNavMobile {
		    	display: block !important;
		    }
	header .mainNav .navItems  {display: none;}
	header .mainNav .logo {
		width: 50%;
	}
	.mobileOpen header .mobileNavItems  {display: block;}

	#home_content_wrapper .slideshow {
		max-height: none;
	}

	#home_content_wrapper .slideshow .slides{
		min-height: 400px;
	}

	#home_content_wrapper .slideshow .cta {
		position: relative;
		display: block;
		background: rgb(24,25,74);
		background: linear-gradient(0deg, rgba(24,25,74,1) 35%, rgba(34,97,161,1) 82%);
	}
	#home_content_wrapper .slideshow .row {
		left: 5% !important;
	}
	#home_content_wrapper .slideshow .cta .flightTrack {
		width: 100%;
	}

	#home_content_wrapper .slideshow .cta .ctaLinks {
		width: 85%;
		margin: 0 auto;
		overflow: hidden;
	}

	#home_content_wrapper .smartAirport {
		display: none;
	}

	.flightTrack {
		display: none;
		.row {
			ul {
				li {
					//padding-right: 25px !important;
				}
			}
		}
	}
	.flightTrackMobile {
		display: block !important;
		padding: 3%;
		width: 85% !important;
		margin: 0 auto;
		margin-bottom: 50px;
		left: 0 !important;
		//padding: 25px !important;

	}
	#internal_content_wrapper {
		.sidebar_container {
			width: 100%;
			text-align: center;
			position: relative;
			margin-left: 0;
			z-index: 2;

			.nav {
				display: none;
			}
			.accordion {
				display: block;
			}
			.panel {
				.nav {
					align-items: flex-start;
					display: flex;
					flex-direction: column;
					margin: 0 auto;
					text-align: left;
					width: 100%;

				}
			}
				.mobileToggleInt {
					position: absolute;
					right: 10%;
					top: -5%;
					font-size: 40px;
					color: $brandPrimary;
				}
		}
		.internal_content_container {
			float: none;
			margin: 0 auto;
		}
	}
}


@media screen and (max-width: 1000px) {
	#home_content_wrapper {
		.explore {
			.box {
				width: 40%;
			}
		}
	}
}



@media screen and (max-width: 850px) {
	footer {
		.logo {
			width: 100%;
			text-align: center;
		}
		.footerNav {
			display: none;
		}
	}

	#ccm-layout-column-wrapper-8 div.ccm-layout-column {
		width: 100% !important;
		margin-top: 40px;

		.ccm-layout-column-inner {
			margin-left: 0 !important;
		}
	}

	#internal_content_wrapper .parkAndTrans .containerRight,
	#internal_content_wrapper .parkAndTrans .containerLeft {
		width: 100%;
		margin-left: 0;
	}

	#BLOCK_204 {
		.owl-wrapper-outer {
			.owl-wrapper {
				.owl-item {
					.item {
						background: rgba(38,34,102,.5);
						background: linear-gradient(0deg, rgba(38,34,102,1) 0%, rgba(66,49,139,1) 100%);
						padding: 50px 0;

						&:before {
							background-image: url("../images/alb-intl-logo-mark-reverse.svg");
							background-position: center;
							background-size: 130%;
							right: 0;
							top: 0;
							opacity: .2;
							height: 100%;
							width: 100%;

						}

						.woc-caption-wrapper {
							width: 100%;

							.woc-caption-holder {

								h3 {
									font-size: 36px;
									margin-bottom: 20px;
								}

								.item-description {
									font-weight: normal;
									font-size: 24px;
									line-height: 1.3em;
									margin-top: 20px;
								}

							}
						}

						.item-btn {
							background: #fff !important;
							color: $brandPrimary !important;
						}
					}

					img {
						display: none;
					}
				}
			}
		}
	}

	#home_content_wrapper {
		.explore {
			.row.mobile-slider {
				display: block;

				#BLOCK_238 {
					.item {
						max-width: 370px;
					}
					.woc-caption-wrapper {
						position: relative;
						width: 100%;
					}
				}
			}
			.desktop {
				display: none;
			}
		}
	}
	
}

@media screen and (max-width: 700px) {
	#home_content_wrapper {
		.explore {
			.box {
				width: 85%;
			}
		}
		.slideshow {
			.slides {
				h2 {
					font-size: 7vw;
				}
			}
		}
	}
	header .mobileToggle {
	    top: 10px;
	}

	.carouselAG .buttonGroup {
		width: 100%;
	}

	.video-wrapper {

		.videos {

			>div {
				flex-direction: column-reverse;
			}
		}
	}
}
