header {
	min-height: unset;
	.headerStyleImage {
		align-items: center;
		display: flex;
		justify-content: center;

		h1 {
			color: white;
			font-size: 3em;
			position: absolute;
			text-transform: uppercase;
			z-index: 10;
		}

		picture {
			&:before {
				background: #000;
				content:'';
				display: block;
				height: 100%;
				left: 0;
				opacity: .4;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 3;
			}
		}
	}
	.mobileToggle {
		display: none;
	}
	.topNav {
		background: rgb(39,170,225);
		background: linear-gradient(90deg, rgba(39,170,225,1) 58%, rgba(177,226,250,1) 90%);
		padding: 10px 5px;
		text-align: right;
		ul {
			margin-bottom: 0;
			li {
				display: inline-block;
				padding-right: 30px;
					&:last-child {
						a {
							font-weight: 600;
						}
					}
				a {
					text-decoration: none;
					color: #18194a;
					font-size: 18px;
					font-weight: 300;
					text-transform: uppercase;

					&:hover {
						color: white;
					}
				}
			}
		}
	}

	.mainNav {
		background: rgb(24,25,74);
		background: linear-gradient(90deg, rgba(24,25,74,1) 64%, rgba(30,81,133,1) 85%, rgba(35,128,182,1) 100%, rgba(39,170,225,1) 100%);
		display: flex;
    	/* text-align: right; */
    	padding: 2%;
		.logo {
			width: 25%;
			img {
				width: 100%;
				max-width: 350px;
			}
		}
		.navItems {
			width: 75%;
			position: relative;
			ul {
				position: absolute;
				right: 0;
				top: 30%;
				li {
					display: inline-block;
					padding-right: 50px;
					a {
						text-decoration: none;
						color: white;
						font-size: 24px;
						text-transform: uppercase;
						font-weight: 700;
						font-style: italic;
						&:hover {
							color: $brandSecondary;
						}
					}
				}
			}
		}
	}
	.mobileNavItems {
			background: rgb(24,25,74);
			background: linear-gradient(0deg, rgba(24,25,74,1) 35%, rgba(34,97,161,1) 82%);
			position: fixed;
		    z-index: 1000;
		    width: 100%;
		    text-align: center;
		    top: 0;
		    height: 100%;
		    overflow: scroll;
		    display: none;
		    #close {
		    	position: absolute;
			    top: 10%;
			    color: white;
			    right: 10%;
			    font-size: 30px;
		    }
		    .sideNavMobile {
		    	display: none;
		    }
		    ul {
		    	position: relative;
			    top: 15%;
			    width: 75%;
			    margin: 0 auto;
			    li {
			    	padding: 10px;
			    	@media screen and (max-width: 700px) {
			    		text-align: left;
			    	}
			    	a {
			    		color: white;
			    		text-decoration: none;
			    		font-weight: 700;
			    		font-size: 24px;
			    		text-transform: uppercase;
			    	}

			    	&:nth-child(n+4) {

			    		a {
			    			font-weight: 100;
			    		}
			    	}
			    }
		    }
		}
}
