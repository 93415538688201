#home_content_wrapper {

	.content {
		max-width: 1200px;
		margin: 0 auto;
	}
	.slideshow {
		background-color: $brandPrimary;
		background-image: url("../images/hero-BG-img.jpg");
		background-position: top center;
		background-size: cover;
		max-height: 800px;
		overflow: hidden;
		position: relative;

		&.one {
			background-image: url("../images/hero-BG-img.jpg");
			background-position: top center;
			background-size: cover;
		}
		&.two {
			background-image: url("../images/hero-BG-img-2.jpeg");
			background-position: top center;
			background-size: cover;
		}
		&.three {
			background-image: url("../images/hero-BG-img-3.jpeg");
			background-position: top center;
			background-size: cover;
		}
		&.four {
			background-image: url("../images/hero-BG-img-4.jpeg");
			background-position: top center;
			background-size: cover;
		}

		.rslides {
			max-height: 900px;
		}
		.slides {
			position: relative;
			min-height: 800px;

			#overlay {
				position: absolute;
			    top: 0;
			    z-index: 100;
			    height: 100%;
			    width: 45%;
			}
			h2 {
				position: absolute;
			    top: 32%;
			    font-size: 5vw;
			    z-index: 500;
			    font-weight: 600;
			    color: white;
			    left: 10%;
			    line-height: 1;
			}
		}
		.cta {
			position: absolute;
			bottom: 0;
			width: 100%;
			background: rgb(24,25,74);
			background: linear-gradient(90deg, rgba(24,25,74,0.9) 64%, rgba(30,81,133,0.9) 85%);
			z-index: 100;
			display: flex;
			padding: 30px 0;
			.ctaLinks {
				width: 50%;
				position: relative;
				margin-left: 4%;
				.row {
					&:nth-child(1) {
						width: 40%;
						@media screen and (max-width: 1200px) {
							margin-left: 10%
						}
						@media screen and (max-width: 700px) {
							margin-left: 5%
						}
					}
					position: relative;
					left: 10%;
					top: 20%;
					padding-bottom: 20px;
					width: 49%;
					display: inline-flex;
					ul {
						li {
							padding-bottom: 10px;
							margin-bottom: 30px;
							@media screen and (max-width: 700px) {
								display: grid;
								text-align: center;
							}
							img {
									padding-right: 10px;
									max-height: 40px;
									width: auto;
									@media screen and (max-width: 700px) {
										max-height: 60px;
										margin: 0 auto;
										padding-bottom: 10px;
										padding-right: 0;
									}
								}
							
							a {
								color: white;
								text-decoration: none;
								font-size: 20px;
								white-space: nowrap;
								padding-bottom: 5px;
								@media screen and (max-width: 700px) {
									white-space: normal;
									font-size: 18px;
								}
								&:hover {
									border-bottom: 2px solid $brandSecondary;
								}
							}
						}
					}
				}
			}
			.flightTrackMobile {
				display: none;
			}
			.flightTrack {
				width: 40%;
				background: rgb(39,170,225);
				background: linear-gradient(0deg, rgba(39,170,225,1) 35%, rgba(118,205,248,1) 82%);
				padding: 1%;
				padding-top: 50px;
				position: relative;
				left: 30px;
				border-radius: 5px;
				padding-left: 3%;
				.row {
					display: flex;
					padding-right: 2%;
					@media screen and (max-width: 700px) {
								display: block;
								text-align: center;
							}
					h3 {
						color: white;
						font-weight: 600;
						font-size: 30px;
						margin-top: -8px;
						text-transform: uppercase;
						width: 45%;
						
						@media screen and (max-width: 700px) {
								font-size: 24px;
								width: 100%;
								text-align: center;
							}
					}
					ul {
						width: 60%;
						margin: 0 auto;
						text-align: right;
						@media screen and (max-width: $lg-breakpoint) {
								width: 100%;
							}
						@media screen and (max-width: 700px) {
								padding-bottom: 20px;
								text-align: center;
							}
						li {
							display: inline-block;
							padding-left: 5%;
							@media screen and (max-width: $lg-breakpoint) {
								padding-right: 5%;
							}
							@media screen and (max-width: 700px) {
								//width: 48%;
								text-align: center;
								margin-top: 15px;
							}
							a {
								color: #18194a;
								text-decoration: none;
								font-size: 20px;
								font-weight: 700;
								text-transform: uppercase;
								@media screen and (max-width: 700px) {
								font-size: 18px;
							    }
							}
						}
					}
				}
				.tracker {
					::-webkit-input-placeholder {
							color: #18194a;
						}
						form {
							display: flex;
						}
					input[type="text"] {
						background-color: unset;
						border: 0;
						box-shadow: none;
						border-bottom: 1px solid white;
						font-size: 20px;
						width: 80%;
						float: left;

						@media screen and (max-width: $lg-breakpoint) {
							padding-right: 5%;
						}
					}
					button[type="submit"] {
						background-color: #18194a;
						color: white;
						float: left;
						margin-left: 30px;
						border-radius: 5px;
						height: fit-content;
						padding: 15px;
					}
				}
			}
		}
	}

	.infoSlide {
		padding: 50px 0;

		.rslides {
			img {
				width: 75%;
				max-width: 1200px;
				float: unset;
				margin: 0 auto;
				-webkit-box-shadow: 5px 7px 5px 5px #DADADA; 
				box-shadow: 5px 7px 5px 5px #DADADA;
			}

			.rslides2_nav {
				background: none;
			}
		}
	}

	.video-wrapper {
		text-align: center;
		min-height: 300px;
		padding-bottom: 50px;

			.videos {
				display: flex;
				margin: 0 auto;
				max-width: 1300px;

				@media screen and (max-width: $lg-breakpoint) {
					flex-direction: column;
					width: 90%;
				}

				img {
					margin: 0 auto;

					@media screen and (max-width: $lg-breakpoint) {
						width: 350px;
					}

					&.play {
						@media screen and (max-width: $lg-breakpoint) {
							width: 75px;
						}
					}
				}

				>div {
					display: flex;
					position: relative;
					margin-top: 40px;
					text-align: left;
					width: 50%;

					@media screen and (max-width: $lg-breakpoint) {
						width: 100%;
					}

					h3 {
						color: #18194a;
						font-weight: 600;
						font-size: 26px;
					}

					p {
						color: #18194a;
						font-size: 20px;
						line-height: 24px;
					}

					>div {
						justify-content: center;
						display: flex;
						flex-direction: column;
						margin: 0 auto;
						position: relative;
						width: 45%;

						&:nth-child(2) {
							padding: 0 45px;
							width: 55%;

							@media screen and (max-width: $lg-breakpoint) {
								width: 100%;
								padding-bottom: 20px;
							}

						}

						@media screen and (max-width: $md-breakpoint) {
							width: 100%;
							text-align: center;
						}
					}

					a {
						position: absolute;
						top: 95px;
						right: -30px;
						transition: all .3s ease-out;

						@media screen and (max-width: $lg-breakpoint) {
							left: 50%;
							top: auto;
							right: auto;
							transform: translateX(-50%);
						}

						&:hover {
							opacity: .9;
							transform: scale(1.1);
						}
					}
				}
			}
	}



	.smartAirport {
		background-image: url("../images/smart-airport-bg.png");
		background-size: cover;
		padding: 120px 50px;
		.text {
			border: 5px solid white;
			border-radius: 5px;
			padding: 40px;
			width: 35%;
			float: left;
			position: relative;
			top: -40px;
			&:after {
				/* font-family: 'Font Awesome 5 Pro';
			    font-weight: 400;
			    content: "\f0da"; */
			    content: '';
			    width: 25px;
			    height: 25px;
			    /* background-image: url("../images/alb-your-smart-airport-caret.svg"); */
			    position: absolute;
			    right: -40px;
			    font-size: 50px;
			    top: 45%;
			    color: white;
			}
			h3 {
				color: #18194a;
				font-weight: 600;
				font-size: 30px;
				text-transform: uppercase;
			}
			p {
				color: #18194a;
				font-size: 24px;
				line-height: 29px;
			}
		}
		.options {
			display: flex;
			margin: 0 auto;
			.box {
				width: 33%;
				padding: 0 50px;
				text-align: center;
				h4 {
					width: 65%;
					margin: 0 auto;
					padding-top: 20px;
					font-size: 22px;
					margin-bottom: 10px;
				}
				img {
					max-height: 105px;
				}
			}
		}
	}

	.explore {
		position: relative;
		text-align: center;
		padding: 50px 0;
		background-image: url("../images/explore-bg.png");
		background-size: cover;

		.decoration {
			position: relative;
			width: 30%;
			max-width: 200px;
			top: -75px;
			margin: 0 auto;
			background: rgb(39,170,225);
			background: linear-gradient(90deg, rgba(39,170,225,1) 35%, rgba(117,205,247,1) 82%);
			padding: 10px 20px;
			border-radius: 5px;
			@media screen and (max-width: 700px) {
				width: 40%;
			}
			h5 {
				color: white;
				margin-bottom: 0;
				font-weight: 600;
				font-size: 28px;
			}
		}
		.row {
				width: 100%;
				max-width: 1400px;
				display: inline-block;
				margin: 0 auto;
				&:nth-child(1) {
					padding-bottom: 50px;
				}

				&.mobile-slider {
					display: none;
				}
			}
			.box {
				width: 31%;
				display: inline-block;
				text-align: left;
				margin: 14px;
				background-color: white;
				vertical-align: top;
				min-height: 430px;
				border-radius: 5px;
				img {
					width: 100%;
				}

				.text {
					padding: 20px 50px;

					h6, p {
						color: #18194a;
					}
					p {
						line-height: 1.5;
						line-height: 19px;
					}
					h6 {
						font-weight: 600;
						font-size: 24px;
					}
					a {
						font-style: italic;
						color: #33afe4;
						text-decoration: none;
					}
				}
			}
	}
}

#BLOCK_204 .owl-controls .owl-buttons div.owl-next,
#BLOCK_239 .owl-controls .owl-buttons div.owl-next{
	opacity: 1;
}

#BLOCK_204 .owl-controls .owl-buttons div.owl-prev,
#BLOCK_239 .owl-controls .owl-buttons div.owl-prev
 {
	opacity: 1;
}


#BLOCK_239 {
		.owl-wrapper-outer {
			.owl-wrapper {
				.owl-item {
					.item {
						img {
							border-radius: 5px;
						}
						.woc-caption-wrapper {
								.woc-caption-holder {
									padding: 0 !important;

									h3 {
										margin-bottom: 20px;
										&:before {
											content: "";
											width: 100px;
											height: 100px;
											background-image: url("../images/alb-intl-logo-mark-blue-01.svg");
											position: absolute;
											top: -70px;
											left: 33%;
											background-size: 100%;
											background-repeat: no-repeat;
										}
									}
									.item-description {
										line-height: 28px;
										font-size: 23px;
									}
									.item-btn {
									}
								}
						}
					}

					&:nth-child(2) {
						.woc-caption-holder {
							h3, p {
								color: #ffffff;
							}
							h3 {
								&:before {
									background-image: url("../images/alb-intl-logo-mark-reverse.svg") !important;
								}
							}
						}
					}
				}
			}
		}
	}

@media screen and (max-width: 900px) {	
	#BLOCK_239 {
		.owl-prev, .owl-next {
			border-color: #ffffff !important;
		}
		.owl-wrapper-outer {
			.owl-wrapper {
				.owl-item {
					.item {
						background: rgb(38,34,102);
						background: linear-gradient(0deg, rgba(38,34,102,1) 0%, rgba(66,49,139,1) 100%);
						display: flex;
						align-items: center;
						min-height: 350px;

						img {
							display: none;
						}

						&:before {
							content: "";
							border: 1px solid white;
							display: block;
							position: absolute;
							background-image: url("../images/alb-intl-logo-mark-reverse.svg");
							background-repeat: no-repeat;
							background-size: 120%;
							background-position: center;
							width: 100%;
							height: 100%;
							opacity: .05;
						}

						.woc-caption-wrapper {
							position: relative;
							max-width: unset;
							margin: 0 auto;
							padding: 5% !important;
							width: 100%;

							.woc-caption-holder {
								width: 100%;
								margin: 0 auto;
								padding: 0 !important;
								h3 {
									font-size: 36px;
									color: white;
									&:before {
										display: none;
									}
								}
								.item-description {
									color: white;	
									font-weight: normal;
									font-size: 26px;
									
								}
								.item-btn {
									background: white;
									color: $brandPrimary !important;
									font-size: 24px;
									border: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

/*@media screen and (max-width: 700px) {	
	#BLOCK_239 {
		.owl-wrapper-outer {
			.owl-wrapper {
				.owl-item {
					.item {
						padding-bottom: 10px;
						.woc-caption-wrapper {
							position: relative;
							max-width: unset;
							margin: 0 auto;
							margin-top: 20px;
							margin-bottom: 20px;
							width: 100%;
								.woc-caption-holder {
									width: 100%;
									margin: 0 auto;
									h3 {
										font-size: 44px;
										&:before {
											
										}
									}
									.item-description {
										
											font-size: 24px;
										
									}
									.item-btn {
										font-size: 24px;
									}
								}
						}
					}
				}
			}
		}
	}
}*/