footer {
	background-color: #18194a;
	display: flex;
	padding: 50px 25px;

	.logo {
		width: 35%;
		img {
			max-width: 400px;
			width: 80%;
		}
	}
	.footerNav {
		width: 60%;
		position: relative;
		ul {
			width: 30%;
    		display: inline-grid;
			li {
				color: white;
				line-height: 1.3em;
				a {
					color: white;
					text-decoration: none;
					text-transform: uppercase;
					line-height: 2;
				}
			}
		}
		.socIcons {
			position: relative;
			bottom: 0;
			right: 0;
			text-align: right;
			width: 69%;
			ul {
				display: inline-block;
				li {
					display: inline-block;
					margin-right: 20px;
					a {
						font-size: 20px;
						&:hover {
							color: $brandSecondary;
						}
					}
				}
			}
		}
	}
}