.headerStyleImage {
	width: 100%;
	position: relative;
	/* &:after {
			position: absolute;
			content: '';
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    background: $brandPrimary;
		    opacity: .5;
		    z-index: 1000;
		} */
	.ccm-image-slider-container {
		.ccm-image-slider {
			.ccm-image-slider-inner {
				&:before {
					background: #000;
					content:'';
					display: block;
					height: 100%;
					left: 0;
					opacity: .2;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 3;
				}
			}
		}
	}
	img {
		width: 100%;
		height: auto;
	}
}
.pure-accordion-block-container {
	h1 {
		font-size: 18px;
	}
}
#internal_content_wrapper {
	@include grid-container;
	max-width: 1800px;
	margin: 0 auto;
	padding: 20px;

	p {
		color: #18194a;
		line-height: 1.5;
		font-size: 18px;
	}
	h2, h3, h4 {
		font-weight: 600;
		color: #422e8b;
		margin-bottom: 20px;
	}
	h2 {
		font-size: 24px;
	}
	h1 {
		font-size: 2em;
		font-weight: 600;
		color: #2261a4;
		margin-bottom: 10px;
	}

	ul {
			list-style-type: disc;
			margin-bottom: 30px;
			padding-left: 20px;

			li {
				font-family: $baseFont;
				line-height: 1.5;
				font-size: 18px;
			}
		}

	.box {
		border-radius: 5px;

		h4 {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}

	}
	.internal_content_container_no_sidebar {
		h1 {
		  font-size: 36px;
		  font-weight: 900;
		  font-stretch: condensed;
		  font-style: italic;
		  line-height: 1.23;
		  letter-spacing: 0.5px;
		  color: $brandPrimary;
		  margin-bottom: 10px;
		  text-align: center;
		  padding-bottom: 40px;
		}
	}
		

	.amenities {
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		flex: 0 0 31.333333%;
		@media screen and (max-width: 700px) {
			flex: 0 0 65%;
		}
		.box {
			@include grid-column(6);
			margin-bottom: 5px;
			text-align: center;
			overflow: hidden;
			@media screen and (max-width: 700px) {
				@include grid-column(12);								
			}
			&:hover {
				img {
					transform: scale(1.2);
				}
			}
			img {
				width: 100%;
				height: auto;
				position: relative;
				z-index: 5;
				transition: 0.5s ease-in-out;
			}
			h4 {
				color: white;
				background: rgb(24,25,74);
				background: linear-gradient(90deg, rgba(24,25,74,1) 35%, rgba(34,97,161,1) 82%);
				padding: 10px;
				position: relative;
				z-index: 6;
			}
		}
	}

	.parkAndTrans {
		@include grid-container;
		.containerLeft {
			@include grid-column(5);
			@include grid-push(1);
			border-radius: 5px;
			@media screen and (max-width: 1200px) {
				@include grid-column(8);
				@include grid-push(2);
				margin-bottom: 50px;
			}
			@media screen and (max-width: 700px) {
				@include grid-column(12);								
			}
			background: rgb(34,97,160);
			background: linear-gradient(180deg, rgba(34,97,160,1) 35%, rgba(51,174,227,1) 82%);
			padding: 50px;
			h2, h3, h4, p {
				color: white;
			}
			h2 {
				font-size: 30px;
				font-weight: 600;
			}
			h3 {
				font-size: 24px;
				font-weight: 600;
				text-transform: uppercase;
			}
			p {
				line-height: 29px;
				font-size: 24px;
				margin: 0;
			}
		}
		.containerRight {
			@include grid-column(5);
			@include grid-push(1);
			margin-left: 0;

			@media screen and (max-width: 1200px) {
				@include grid-column(8);
				@include grid-push(2);
			}
			.box {
			@include grid-column(12);
			overflow: hidden;
			margin-bottom: 20px;
			text-align: center;
			border-radius: 5px;
			&:hover {
				img {
					transform: scale(1.2);
				}
			}
			img {
				width: 100%;
				height: auto;
				position: relative;
				z-index: 5;
				transition: 0.5s ease-in-out;
			}
			h4 {
				color: white;
				background: rgb(24,25,74);
				background: linear-gradient(90deg, rgba(24,25,74,1) 35%, rgba(34,97,161,1) 82%);
				padding: 10px;
				position: relative;
				z-index: 6;
			}
		}
		}
	}

	.airlines {

		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		flex: 0 0 31.333333%;
		@media screen and (max-width: 700px) {
			flex: 0 0 65%;
		}
			.box {
				text-align: center;
				padding: 50px;
				border: 1px solid lightgrey;
				flex: inherit;
				margin: 10px;
				border-radius: 5px;
				overflow: hidden;
				img {
					vertical-align: middle;
				}
			}
	}

	.airportGuide {
		@include grid-container;
		.cta {
			max-width: 1200px;
			margin: 0 auto;
		}
		.box {
			@include grid-column(6);
			overflow: hidden;
			@media screen and (max-width: 700px) {
				@include grid-column(12);								
			}
			&:hover {
				img {
					transform: scale(1.2);
				}
			}
			margin-bottom: 20px;
			text-align: center;
			img {
				width: 100%;
				height: auto;
				z-index: 5;
				position: relative;
				transition: 0.5s ease-in-out;
			}
			h4 {
				color: white;
				background: rgb(24,25,74);
				background: linear-gradient(90deg, rgba(24,25,74,1) 35%, rgba(34,97,161,1) 82%);
				padding: 10px;
				z-index: 6;
				position: relative;
			}
		}
	}
.airportGuideAmenities {
		@include grid-container;
		padding: 35px 0;
		.box {
			@include grid-column(4);
			margin: 10px;
			overflow: hidden;

			@media screen and (max-width: 1000px) {
				@include grid-column(6);								
			}

			@media screen and (max-width: 700px) {
				@include grid-column(12);								
			}
			&:hover {
				img {
					transform: scale(1.2);
				}
			}
			text-align: center;
			img {
				width: 100%;
				height: auto;
				position: relative;
				z-index: 5;
				transition: 0.5s ease-in-out;
			}
			h4 {
				color: white;
				background: rgb(24,25,74);
				background: linear-gradient(90deg, rgba(24,25,74,1) 35%, rgba(34,97,161,1) 82%);
				padding: 10px;
				position: relative;
				z-index: 6;
			}
		}
	}


	.sidebar_container {
		@include grid-column(2);

					/* Style the buttons that are used to open and close the accordion panel */
				.accordion {
					display: none;
					background-color: $brandPrimary;
					color: #fff;
					cursor: pointer;
					padding: 18px;
					width: 100%;
					text-align: left;
					border: none;
					outline: none;
					transition: 0.4s;
					margin: 0 auto;
					margin-bottom: 20px;
					font-size: 24px;
				}

				/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
				.active, .accordion:hover {
				  
				}

				/* Style the accordion panel. Note: hidden by default */
				.panel {
				  padding: 0 18px;
				  background-color: white;
				  max-height: 0;
				  overflow: hidden;
				  transition: max-height 0.2s ease-out;
				  margin-bottom: 20px;
				}

				.accordion:after {
				  content: '\f0d7'; /* Unicode character for "plus" sign (+) */
				  font-size: 24px;
				  color: #fff;
				  float: right;
				  margin-left: 5px;
				  font-family: 'Font Awesome 5 Pro';
				}

				.active:after {
				  content: "\f0d8"; /* Unicode character for "minus" sign (-) */
				}
		ul {
			list-style-type: none;
			padding-left: 0;

			li {
				margin-bottom: 20px;
				padding-bottom: 10px;
				border-bottom: 1px solid #f1fbff;
				a {
					color: #2261a4;
					text-decoration: none;
					font-size: 24px;
					font-style: italic;
					font-weight: 600;
					line-height: 1em;
				}
			}
		}
	}
	.internal_content_container {
		@include grid-column(9);
		@include grid-push(1);
		max-width: 1200px;
	}

	@media screen and (max-width: 1000px) {
		.internal_content_container {
			@include grid-column(12);
		}
		.sidebar_container {
			@include grid-column(12);
			text-align: center;
		}
	}
}

	.carouselAG {
		background: rgb(223,244,255);
		background: linear-gradient(180deg, rgba(223,244,255,1) 35%, rgba(175,227,249,1) 82%);
		padding: 40px 0;

		.buttonGroup {
			width: 50%;
			margin: 0 auto;
			text-align: center;
			padding-bottom: 40px;
			li {
				width: 30%;
				display: inline-block;
				text-align: center;

				@media screen and (max-width: 1200px) {
					width: 49%;
				}
				a {
					text-decoration: none;
					font-size: 24px;
					font-weight: 400;
					color: $brandPrimary;
					text-transform: uppercase;
				}
				.active {
					border-bottom: 3px solid $brandSecondary;
				}

				#shopping {
					padding-bottom: 5px;
					&:before {
						font-family: 'Font Awesome 5 Pro';
					    font-weight: 900;
					    content: "\f290";
					    padding-right: 5px;
					    position: relative;
					    font-size: 24px;
					    color: $brandSecondary;
					}	
				}
				#dining {
					padding-bottom: 5px;
					&:before {
						font-family: 'Font Awesome 5 Pro';
					    font-weight: 900;
					    content: "\f2e6";
					    padding-right: 5px;
					    position: relative;
					    font-size: 24px;
					    color: $brandSecondary;
					}	
				}
				.underline {
					border-bottom: 3px solid $brandSecondary;
				}
			}
		}
		#carouselOne, #carouselTwo {
			max-width: 1400px;
			margin: 0 auto;
			width: 90%;
		}
		#carouselTwo {
			display: none;
		}
		.vivid-carousel {
			position: relative;
			.carousel-header-wrap {
				position: absolute;
				top: 50%;
				width: 100%;
				z-index: 1000;
				.carousel-nav-button.prev {
					left: 20px;
					background: none;
					&:before {

					}
				}
				.carousel-nav-button.next {
					right: 20px;
					background: none;
				}
			}
			ul.carousel-item-container {
				
				margin: 0 auto;
				li.carousel-item {
						.item-inner {
					    width: 70%;
					    margin: 0 auto;
					    background-color: white;
					    min-height: 400px;
					}
					img {
						width: 100%;
					    max-height: 230px;
					    height: auto;
					}
					h3, h4, p {
						width: 80%;
						margin: 0 auto;
					}
					h3 {
						padding-top: 20px;
						color: $brandPrimary;
						font-size: 20px;
						font-weight: 600;
					}
					p {
						padding: 10px 0;
						font-size: 16px;
					}
					h4 {
						color: #33afe4;
						font-size: 18px;
					}
				}
			}
		}
	}



/* Arrivals and Departures List
=============================================================================================================*/
#tabs {
	.buttonGroup {
		list-style-type: none !important;
		margin: 0 !important;
		padding: 0 !important;

		li {
			margin: 0 !important;
		}

		.selected {
			position: relative;
			&:after {
				font-family: 'Font Awesome 5 Pro';
			    font-weight: 600;
			    content: "\f0d8";
			    position: absolute;
			    right: 30px;
			    bottom: -68px;
			    font-size: 60px;
			    color: #2261a4;
			    @media screen and (max-width: 700px) {
			    	display: none;
			    }
			}
		}
		li {
			display: inline-block;
			width: 24%;
			padding: 0 10px;
			&:first-child {
					&:before {
						font-family: 'Font Awesome 5 Pro';
					    font-weight: 100;
					    content: "\f5af";
					    right: 0;
					    font-size: 28px;
					    color: $brandSecondary;
					    padding-right: 5px;
					}
				}
				&:nth-child(2) {
					&:before {
						font-family: 'Font Awesome 5 Pro';
					    font-weight: 100;
					    content: "\f5b0";
					    right: 0;
					    font-size: 28px;
					    color: $brandSecondary;
					    padding-right: 5px;
					}
				}
				&:nth-child(3) {
					&:before {
						font-family: 'Font Awesome 5 Pro';
					    font-weight: 100;
					    content: "\e024";
					    right: 0;
					    font-size: 28px;
					    color: $brandSecondary;
					    padding-right: 5px;
					}
				}



				
			a {
				color: $brandPrimary;
				font-size: 18px;
				text-decoration: none;
				font-style: italic;
				font-weight: 600;
				&:hover {
					color: $brandSecondary;
				}
			}
			form {
				position: relative;
					input {
					display: inline-block;
					border: none;
					border-bottom: 1px solid $brandSecondary;
					border-radius: 0;
					box-shadow: none;
					}
						&:after {
						font-family: 'Font Awesome 5 Pro';
					    font-weight: 400;
					    content: "\f002";
					    position: absolute;
					    right: 0;
					    font-size: 28px;
					    color: $brandSecondary;
				}
			}
		}

		@media screen and (max-width: 700px) {
					.selected {
						position: relative;
						&:after {
							font-family: 'Font Awesome 5 Pro';
						    font-weight: 600;
						    content: "\f0d9";
						    font-size: 26px;
						    color: #2261a4;
						    position: initial;
						}
					}

						li {
							width: 100%;
							padding: 10px;
							text-align: center;
						}
					
				}
	}
}


.adTables {

	table {
		margin: .5em 0;
		
		tr {
			th {
				background: #2261a4;
				color: white;
				padding: 20px 10px;
				@media screen and (max-width: 700px) {
					&:first-child {
					display: none;
					}
				}
			}

			@media screen and (max-width: 700px) {
					td {
						&:first-child {
							display: none;
						}	
						font-size: 14px;
					}
			}
		}
	}
}

#tabs-2 {
	display: none;
}


#BLOCK_200 .owl-controls .owl-buttons div.owl-next,
#BLOCK_202 .owl-controls .owl-buttons div.owl-next {
	opacity: 1;
}

#BLOCK_200 .owl-controls .owl-buttons div.owl-prev,
#BLOCK_202 .owl-controls .owl-buttons div.owl-prev {
	opacity: 1;
}