/* HEADER */

header { 
  min-height: 100px;
  width: 100%;
  clear: both;
  background-color: white;
  position: relative;

}

.blue-button {
	a {
		color: white;
		background: $btnColor;
		text-transform: uppercase;
		padding: 10px 20px;
	}
}

.mobile {
	display: block;

	@media screen and (min-width: $lg-breakpoint) {
		display: none;							
	}
}

.desktop {
	display: none;

	@media screen and (min-width: $lg-breakpoint) {
		display: block;							
	}
}